import React from "react";
import "../styles/FollowUs.css";

const FollowUs = () => {


    return (
        <div className="FollowUs">
            <img alt="" src={`https://blogapi.crazysoziety.net/images/insta_1.jpg`} />
            <img alt="" src={`https://blogapi.crazysoziety.net/images/insta_2.jpg`} />
            <img alt="" src={`https://blogapi.crazysoziety.net/images/insta_3.jpeg`} />
            <img alt="" src={`https://blogapi.crazysoziety.net/images/insta_4.jpeg`} />
            <img alt="" src={`https://blogapi.crazysoziety.net/images/insta_5.jpeg`} />
        </div>
    )
}


export default FollowUs;